<template>
<div class='page'>
 
 <industrialbanner :IndustrialbannerData="IndustrialbannerData"></industrialbanner>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
 <industrieshead :industriesheadLists="industriesheadLists"></industrieshead>
   <applications2 :ApplicationsLists="ApplicationsLists"></applications2>
    <capabilities :CapabilitiesList="CapabilitiesList"></capabilities>
  <customers></customers>
 
</div>
</template>
<script>
import bread from '../../components/bread.vue'
import industrialbanner from '../../components/Industrialbanner.vue'
import myhead from "../../components/myHead.vue";
import industrieshead from "../../components/industrieshead.vue";
import applications2 from "../../components/Applications2.vue";
import customers from "../../components/Customers.vue"
import capabilities from "../../components/Capabilities.vue"

export default {
    components: {
    myhead,
    industrieshead,
    applications2,
    customers,
    capabilities,
    industrialbanner,
    bread,
  },
  data() {
    return {
          hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
           CapabilitiesList:{
        H1:'AEROSPACE POST-PROCESSING CAPABILITIES',
        lists:[{ 
            title:'Anodizing',
        },{
            title:'Passivization',
        },{
            title:'Electroplating',
        },{
            title:'Powder Coating',
        },{
            title:'Insert Installation',
        },{
            title:'Heat Treatment',
        },],
        img: require('../../assets/img/AerospaceUAVca1.png')
    },
      IndustrialbannerData:{
				H1:'Aerospace & UAV',
				info:'HLH is your 3D manufacturing partner from prototype to large scale production.',
				background:'#0B090A',
				img:require('../../assets/img/AerospaceUAVbanner.png'), 
		
			},

      industriesheadLists: {
        ref: "aerospaceUAV",
        H1: "AEROSPACE & UAV",
        info: `Aerospace was one of the first industries to use CNC machining. This is due to its ability to manufacture lightweight parts with excellent physical properties and very tight tolerances. CNC machining is used both for aircraft parts and also during the development stages. At HLH, we offer tailored solutions to the aerospace and UAV markets enabling them to rapidly innovate new solutions. We manufacture parts in a wide range of aerospace grade materials and offer high quality solutions via CNC Machining.`,
        imgs: [
          {
            src: require("../../assets/img/aerospaceUAV1.png")
          }
        ],
        h2: "Top Aerospace & UAV Applications",
        data: [
          {
            content:
              "End-use production of flightworthy components with high-precision tolerances"
          },
          {
            content:
              "Customized small production runs for repairs or replacement"
          },
          {
            content:
              "Rapid prototyping of parts using CNC machining"
          },
          {
            content: "Custom fixturing"
          },
          {
            content:
              "Fit check gauges"
          }
        ]
      },
      ApplicationsLists: {
        H1: "WHY AEROSPACE COMPANIES CHOOSE HLH？",
        data: [
          {
            img: require("../../assets/img/aerospaceUAV1model1.png"),
            title: "Faster Cycle Times",
            info: "With quotes in minutes and parts in days, aerospace engineers can reduce cycle times by as much as 50% with HLH."
          },
          {
            img: require("../../assets/img/aerospaceUAV1model3.png"),
            title: "Automated CNC Machining",
            info: "Leverage high-speed 3-axis, 4-axis and 5-axis milling processes as well as turning with live tooling for increasingly complex metal and plastic components."
          },
          {
            img: require("../../assets/img/aerospaceUAV1model2.png"),
            title: "Industry Leading Quality",
            info: "Quality services include material certifications, certificates of conformity, advanced inspection reports and more."
          }
        ]
      }
    };
  },
  
  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {},

};
</script>
<style scoped lang="scss">
.page {
}
.bannerBg {
  width: 100%;
  height: 620px;
  background: red;
}
</style>